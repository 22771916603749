import * as React from 'react';
import '@fontsource/comfortaa/300.css'; // Weight 300.
import '@fontsource/comfortaa'; // Defaults to weight 400.
import '@fontsource/comfortaa/500.css'; // Weight 500.
import '@fontsource/comfortaa/600.css'; // Weight 600.
import '@fontsource/comfortaa/700.css'; // Weight 700.
import Layout from '../components/layout';
import styled from 'styled-components';
import Container from '@material-ui/core/Container';

const PrivacyStyled = styled.div`
	font-size: 20px;
`;
// styles
const pageStyles = {
	color: '#232129',
	webkitFontSmoothing: 'antialiased',
	fontFamily: 'Comfortaa',
	marginTop: '3em',
};
const headingStyles = {
	marginTop: 0,
	marginBottom: 64,
	maxWidth: 320,
};
// markup
const PrivacyPage = () => {
	return (
		<PrivacyStyled>
			<Layout>
				<Container>
					<div style={{ margin: '6em 2em' }}>
						<div class='elementor-widget-container'>
							<p>
								Grow My Life is committed to providing quality services to you
								and this policy outlines our ongoing obligations to you in
								respect of how we manage your Personal Information. We have
								adopted the Australian Privacy Principles (APPs) contained in
								the Privacy Act 1988 (Cth) (the Privacy Act). The APPs govern
								the way in which we collect, use, disclose, store, secure and
								dispose of your Personal Information. A copy of the Australian
								Privacy Principles may be obtained from the website of The
								Office of the Australian Information Commissioner at{' '}
								<a
									class='c-link'
									href='http://www.aoic.gov.au/'
									target='_blank'
									rel='noopener noreferrer'
									data-stringify-link='http://www.aoic.gov.au'
									data-sk='tooltip_parent'
								>
									www.aoic.gov.au
								</a>
							</p>
							<p>
								<strong>
									What Is Personal Information And Why Do We Collect It?
								</strong>
							</p>
							<p>
								Personal Information is information or an opinion that
								identifies an individual. Examples of Personal Information we
								collect may include contact information such as names,
								addresses, email addresses, social media handles and phone
								number. This Personal Information may be requested in ways
								including interviews, correspondence, by telephone, by social
								media, by email, or directly via our web app{' '}
								<a
									class='c-link'
									href='https://app.growmylife.com.au/'
									target='_blank'
									rel='noopener noreferrer'
									data-stringify-link='https://app.growmylife.com.au'
									data-sk='tooltip_parent'
								>
									https://app.growmylife.com.au
								</a>
								, and it may be from your website, from media and publications,
								from other publicly available sources, from cookies and from
								third parties. We don’t guarantee privacy policies of website
								links that may be on our platform from time to time nor the
								policy of any authorised third parties. However, we will
								endeavour to act to remove links or to otherwise attempt to
								remediate the situation within a reasonable period of time if we
								are notified that a third-party site or link visible or
								clickable from our site is in breach of the privacy principles
								we uphold.&nbsp;(You may notify us via our provided in-app
								feedback form.)&nbsp;We collect your Personal Information for
								the primary purpose of providing our services to you, providing
								information to our clients, marketing and to enable our machine
								learning algorithms to operate to maximum effectiveness to
								provide you with the best possible tailored experience. We may
								also use your Personal Information for secondary purposes
								closely related to the primary purpose, in circumstances where
								you would reasonably expect such use or disclosure. You may
								unsubscribe from our mailing/marketing lists at any time by
								contacting us in writing or by clicking our unsubscribe link in
								any emails we send from time to time. When we collect Personal
								Information we will, where appropriate and where possible,
								explain to you why we are collecting the information and how we
								plan to use it.
							</p>
							<p>
								<strong>Sensitive Information</strong>
							</p>
							<p>
								Sensitive information is defined in the Privacy Act to include
								information or opinion about such things as an individual’s
								racial or ethnic origin, political opinions, membership of a
								political association, religious or philosophical beliefs,
								membership of a trade union or other professional body, criminal
								record or health information. Sensitive information will be used
								by us only: For the primary purpose for which it was obtained,
								or for a secondary purpose that is directly related to the
								primary purpose, with your consent; or where required or
								authorised by law.
							</p>
							<p>
								<strong>Third Parties</strong>
							</p>
							<p>
								Where reasonable and practicable to do so, we will collect your
								Personal Information only from you. However, in some
								circumstances we may be provided with information by third
								parties. In such a case we will take reasonable steps to ensure
								that you are made aware of the information provided to us by the
								third party.
							</p>
							<p>
								<strong>Disclosure Of Personal Information</strong>
							</p>
							<p>
								Your Personal Information may be disclosed in a number of
								circumstances including the following: Third parties where you
								consent to the use or disclosure; and where required or
								authorised by law.
							</p>
							<p>
								<strong>Security Of Personal Information</strong>
							</p>
							<p>
								Your Personal Information is stored in a manner that reasonably
								protects it from misuse and loss and from unauthorized access,
								modification or disclosure. When your Personal Information is no
								longer needed for the purpose for which it was obtained, we will
								take reasonable steps to destroy or permanently de-identify your
								Personal Information. However, most of the Personal Information
								is or will be stored in client files which will be kept by us
								for a minimum of 7 years. We will delete any such Personal
								Information on request (via our feedback form or via email).
							</p>
							<p>
								<strong>Access To Your Personal Information</strong>
							</p>
							<p>
								You may access the Personal Information we hold about you and to
								update and/or correct it, subject to certain exceptions. If you
								wish to access your Personal Information, please contact us via
								our feedback form or via email. We will not charge any fee for
								your access request, but may charge an administrative fee for
								providing a copy of your Personal Information. In order to
								protect your Personal Information we may require identification
								from you before releasing the requested information.
							</p>
							<p>
								<strong>
									Maintaining The Quality Of Your Personal Information
								</strong>
							</p>
							<p>
								It is an important to us that your Personal Information is up to
								date. We will take reasonable steps to make sure that your
								Personal Information is accurate, complete and up-to-date. If
								you find that the information we have is not up to date or is
								inaccurate, please advise us as soon as practicable so we can
								update our records and ensure we can continue to provide quality
								services to you.
							</p>
							<p>
								<strong>Policy Updates</strong>
							</p>
							<p>
								This Policy may change from time to time and is available on our
								website. Privacy Policy Complaints &amp; Enquiries. If you have
								any queries or complaints about our Privacy Policy, please
								contact us at:{' '}
								<a
									class='c-link'
									href='mailto:support@growmylife.com.au'
									target='_blank'
									rel='noopener noreferrer'
									data-stringify-link='mailto:support@growmylife.com.au'
									data-sk='tooltip_parent'
								>
									support@growmylife.com.au
								</a>
							</p>
							<p>
								<strong>Contact Us</strong>
							</p>
							<p>
								The best way to message us via our feedback form or via email{' '}
								<a
									class='c-link'
									href='mailto:support@growmylife.com.au'
									target='_blank'
									rel='noopener noreferrer'
									data-stringify-link='mailto:support@growmylife.com.au'
									data-sk='tooltip_parent'
								>
									support@growmylife.com.au
								</a>
							</p>
						</div>
					</div>
				</Container>
			</Layout>
		</PrivacyStyled>
	);
};

export default PrivacyPage;
